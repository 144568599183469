import { DuplicateFieldName } from '@hooks/use-duplicate-check';

const signup = {
    signup: {
        signup: '회원가입',
        apply: '가입하기',
        nextStep: '다음',
        checkDuplicate: '중복확인',
        duplicateChecked: '확인완료',
        checkEmail: '인증요청',
        confirmEmail: '인증확인',
        emailConfirmed: '인증완료',

        identityVerification: '본인인증',
        verifyIdentification: '본인인증 하기',
        changePassword: '비밀번호 변경',
        signupPage: '회원정보 입력',

        identityVerificationFailed: '본인인증을 실패하였습니다. 다시 시도해주세요.',
        duplicatedField: '이미 사용중인 {fieldName}입니다.',
        verifiedField: '사용 가능한 {fieldName}입니다.',

        username: '아이디',
        name: '이름',
        phoneNumber: '휴대폰번호',
        nickname: '닉네임',
        email: '이메일',
        birthdate: '생년월일',
        gender: '성별',
        password: '비밀번호',
        confirmPassword: '비밀번호 확인',
        passwordConfirm: '비밀번호 확인', // deprecated
        offlineStoreMembership: '선호매장',

        namePlaceholder: '이름을 입력해 주세요.',
        usernamePlaceholder: '아이디를 입력해 주세요.',
        nicknamePlaceholder: '닉네임을 입력해 주세요.',
        passwordPlaceholder: '비밀번호를 입력해 주세요.',
        passwordCheckPlaceholder: '비밀번호를 한번 더 입력해 주세요.',
        phoneNumberPlaceholder: "'-'없이 입력해 주세요.",
        emailPlaceholder: 'example@naver.com',
        birthdatePlaceholder: '생년월일을 입력해 주세요.',
        codePlaceholder: '인증코드를 입력해 주세요.',
        offlineStoreMembershipPlaceholder: '선호매장을 선택해 주세요.',

        'safe-1PasswordError': '영문 대소문자/숫자/특수문자 중 2가지 이상조합의 10~16자로 구성된 비밀번호를 입력해 주세요.',
        'safe-2PasswordError': '영문 대소문자/숫자/특수문자 중 3가지 이상조합의 8~16자로 구성된 비밀번호를 입력해 주세요.',
        'regular-1PasswordError': '영문 대소문자/숫자/특수문자 중 2가지 이상조합의 8~16자로 구성된 비밀번호를 입력해 주세요.',
        passwordMatched: '비밀번호가 일치합니다.',
        newPasswordMatched: '새 비밀번호가 일치합니다.',
        passwordNotMatched: '비밀번호와 일치하지 않습니다. 다시 입력해 주세요.',
        newPasswordNotMatched: '새 비밀번호와 일치하지 않습니다. 다시 입력해 주세요.',
        emailRequiredForPasswordChange: '아이디, 비밀번호 찾기를 위해 이메일 입력이 필요합니다.',

        codeSent: '입력하신 이메일로 인증코드가 전송되었습니다.',
        codeSentError: '인증코드 전송에 실패했습니다.',
        timeLeftForCode: '인증코드 재요청은 {seconds}초 후에 가능합니다.',
        codeTimeExpired: '제한시간이 초과되었습니다. 인증요청을 다시 해주세요.',
        codeIncorrect: '인증코드가 잘못되었습니다 다시 입력해 주세요.',
        codeCorrect: '인증이 완료 되었습니다.',

        nameCannotBeUsed: '이름은 영문, 한글만 사용 가능합니다.',
        nicknameCannotBeUsed: '닉네임은 최소 2자 이상, 최대 10자 이하로 입력해 주세요.',
        emailCannotBeUsed: '잘못된 형식의 이메일입니다. 영문, 숫자, 일부 특수기호( . _ @)만 사용할 수 있습니다.',
        usernameCannotBeUsed: '아이디는 최소 {minimum}자 이상, 최대 {n}자 미만의 영문, 숫자만 사용할 수 있습니다.',
        phoneNumberCannotBeUsed: '사용할 수 없는 휴대폰 번호입니다.',
        birthdateCannotBeUsed: '사용할 수 없는 생년월일입니다.',
        genderCannotBeUsed: '사용할 수 없는 성별입니다.',

        duplicateEmail: '이미 가입한 이메일입니다.',
        duplicatePassword: '이전에 사용했던 비밀번호는 사용할 수 없습니다.',
        emailDoesNotExist: '입력하신 정보와 일치하는 회원정보를 찾을 수 없습니다. 다시 입력해 주세요.',

        nicknameVerified: '사용 가능한 닉네임입니다.',
        usernameVerified: '사용 가능한 아이디입니다.',
        phoneNumberVerified: '사용 가능한 전화번호입니다.',

        verifyFail: '다른 고객이 해당 정보로 회원가입을 완료했습니다.\n[{keys}]',
        requiredFail: '필수값을 입력해 주세요.\n[{keys}] ',
        duplicateCheckFail: '중복확인을 해주세요.\n[{keys}] ',
        emailVerificationFailed: '이메일 인증을 해주세요.',

        genderPlaceHolder: '성별을 설정해주세요.',

        changePasswordGuide: '{channelName}에 오신 것을 환영합니다.\n안전한 개인정보를 위해 비밀번호를 변경해 주세요.',
        passSignupGuide: '{channelName}에 오신 것을 환영합니다.\n회원가입을 위해서 약관 동의 및 본인인증이 필요합니다.',
        identityVerificationFail:
            '사용자 정보와 PASS 인증 정보가 일치하지 않습니다.\n다시 시도해 주세요.\n\n문제가 지속될 경우 아래 고객센터로 연락 바랍니다.\n확인 버튼을 누르면 로그인 화면으로 이동합니다.',
        passFailed: '본인인증에 실패했습니다.',

        passwordChangePageExitAlert: '비밀번호 변경을 완료하지 않으셨습니다.\n정말로 홈 화면으로 이동하시겠습니까?',
        passwordChangePageExitAlertDescription: '(비밀번호 변경을 완료하지 않을 경우, 로그아웃되어 홈 화면으로 이동합니다.)',
    },
    duplicate: {
        [DuplicateFieldName.Nickname]: '이미 사용중인 닉네임입니다.',
        [DuplicateFieldName.PhoneNumber]: '이미 사용중인 전화번호입니다.',
        [DuplicateFieldName.Username]: '이미 사용중인 아이디입니다.',
        [DuplicateFieldName.Email]: '이미 사용중인 이메일입니다.',
    },
};

export default signup;
