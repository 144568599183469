import ApiService from '@api/api.service';
import AuthClient, { authClient } from '@api/auth/auth.client';
import CONFIG, { default as HARMONY_CONFIG } from '@config';
import styled from '@emotion/styled';
import { useExtension } from '@hooks/use-extension';
import { useIsUserValidated } from '@hooks/use-is-user-validated';
import { useKakaoTracking } from '@hooks/use-kakao-ad-tracker';
import { TermsAgreeType, TermsType } from '@hooks/use-terms';
import { useToast } from '@hooks/use-toast';
import LegacyFullPageLoader from '@legacy/components/common/legacy-full-page-loader';
import { AlertStatusEnum, AlertTypeEnum } from '@legacy/designs/alert';
import { useAlert } from '@legacy/hooks/use-alert-drawer';
import { useErrorDrawer } from '@legacy/hooks/use-error-drawer';
import { AuthTypeEnum } from '@models/auth-setting/responseDto/oauth.dto';
import Customer from '@models/customer';
import { LoginStatus } from '@models/login-token-based/responseDto/login.dto';
import { Member } from '@models/member';
import OAuthTerms from '@models/oauth-terms';
import { OauthErrorCode } from '@models/oauth-terms/responseDto/oauth-terms.dto';
import { MobileWebviewService } from '@services/mobile-webview.service';
import safeWindow from '@services/safe-window.service';
import { FixedContainer } from '@styles/styled-components';
import { AuthErrorsEnum } from '@type/errors';
import { LoginType } from '@type/harmony-config';
import { MarketDomain } from '@type/markets';
import DomainUtils from '@utils/domain.utils';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import RoutesEnum from 'src/routes/routes';
import { useSWRConfig } from 'swr';
import {
    CustomerLogin,
    CustomerProp,
    CustomerProviderProps,
    DeregisterAccount,
    ExternalTokenLogin,
    GetCustomer,
    Logout,
    RedirectToLogin,
    Register,
    UseCustomerOptions,
} from './types';

export const CustomerContext = React.createContext<CustomerProp>({} as CustomerProp);

/**
 * Provides global context for the logged in customer.
 */
export const CustomerProvider: React.FC<CustomerProviderProps> = ({ children, postData }) => {
    const kakaoTracking = useKakaoTracking();

    const { isValidated, isPending } = useIsUserValidated();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingTerms, setIsLoadingTerms] = useState<boolean>(false);
    const { extensionStatus } = useExtension();
    const [customer, setCustomer] = useState<Customer | undefined>(undefined);
    const [member, setMember] = useState<Member | undefined>(undefined);
    const [isIdentificationVerified, setIsIdentificationVerified] = useState<boolean>(false);
    const router = useRouter();
    const { mutate } = useSWRConfig();
    const errorDrawer = useErrorDrawer();
    const { showAlert } = useAlert();
    const { showToast } = useToast();
    const t = useTranslations('common');
    const tErrors = useTranslations('errors');
    const isAuth = !!customer;

    useEffect(() => {
        if ((DomainUtils.isKbPay || DomainUtils.isKbOnnuriShop || DomainUtils.isJejuPass) && isAuth) {
            authClient.postTokenValidation();
        }
    }, [router.asPath]);

    const externalUserId = router.query.external_user_id || router.query['authorization-freed'] || router.query['token'] || router.query.accessToken;
    /** Additional parameter for kbpay */
    const isInApp = router.query.isInApp;
    if (isInApp) {
        Cookies.set('isInApp', `${isInApp}`);
    }

    const handleErrors = (response: any, callback: () => void) => {
        if (response.status === 409 && response.data.error?.code === OauthErrorCode.UserExist) {
            let authName: string;
            let existType = t('emailAddress');
            switch (response.data.error?.data?.auth_type) {
                case AuthTypeEnum.Naver:
                    authName = t(AuthTypeEnum.Naver);
                    break;
                case AuthTypeEnum.Kakao:
                    authName = t(AuthTypeEnum.Kakao);
                    break;
                case AuthTypeEnum.Google:
                    authName = t(AuthTypeEnum.Google);
                    break;
                default:
                    authName = 'ID/PW';
            }

            if (response.data.error?.data && Object.keys(response.data.error.data).includes('phone_number')) {
                existType = t('phone');
            }

            showAlert({
                children: t('oauthError', { authType: authName, existType }),
                status: AlertStatusEnum.Alert,
                onClose: callback,
            });
        } else if (response.status === 400 && response.data.error?.code === OauthErrorCode.OauthMissingPhone) {
            showAlert({
                children: (
                    <>
                        {t('missingPhone')}
                        <br />
                        {t('missingPhone2')}
                    </>
                ),
                status: AlertStatusEnum.Alert,
                onClose: callback,
            });
        } else {
            errorDrawer(undefined, callback);
        }
    };

    /**
     * Get customer
     */
    const getCustomer: GetCustomer = async () => {
        try {
            setIsLoading(true);
            const customer = await authClient.getCustomer();
            setCustomer(new Customer(customer));
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Email and password registration.
     */
    const register: Register = async (email, password) => {
        try {
            setIsLoading(true);
            const customer = await authClient.register(email, password);
            setCustomer(new Customer(customer));
        } catch (error) {
            if (error instanceof AxiosError && error.response?.data.error.code === AuthErrorsEnum.IdVerificationFailed) {
                showAlert({
                    children: tErrors(`${AuthErrorsEnum.IdVerificationFailed}`),
                    status: AlertStatusEnum.Alert,
                    type: AlertTypeEnum.Drawer,
                });
                return;
            }

            errorDrawer();
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Username and password login.
     */
    const loginCustomer: CustomerLogin = async (username, password) => {
        try {
            setIsLoading(true);
            const loginStatus = await authClient.login(username, password);

            if (loginStatus === LoginStatus.VerificationRequired) {
                setIsIdentificationVerified(false);
                router.push({
                    pathname: RoutesEnum.Terms,
                    query: {
                        type: TermsType.PasswordChange,
                    },
                });
                return;
            }

            if (loginStatus === LoginStatus.PasswordResetRequired) {
                setIsIdentificationVerified(true);
                router.push(RoutesEnum.PasswordChange);
                return;
            }

            kakaoTracking.login();
            const customer = await authClient.getCustomer();
            setCustomer(new Customer(customer));
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Logout from user account.
     */
    const logout: Logout = async () => {
        try {
            setIsLoading(true);
            await authClient.logout();
            setCustomer(undefined);
            mutate(() => true, undefined);
            router.push(RoutesEnum.Login);
        } catch (error) {
            errorDrawer();
        } finally {
            setIsLoading(false);
        }
    };

    const deregisterAccount: DeregisterAccount = async () => {
        showAlert({
            children: t('confirmDeleteAccount'),
            buttons: {
                type: 'async',
                onClickAsync: async () => {
                    try {
                        setIsLoading(true);
                        await authClient.deregisterAccount();
                        setCustomer(undefined);
                        mutate(() => true, undefined);
                        await router.push(RoutesEnum.Home);
                        showToast({ description: t('deleteAccountComplete') });
                    } catch (error) {
                        errorDrawer();
                    } finally {
                        setIsLoading(false);
                    }
                },
                asyncBtnText: t('withdraw'),
            },
        });
    };

    /**
     * Third party Oauth login
     */
    const oauthLogin = async (authType: string): Promise<void> => {
        try {
            if (!authType && !router.query.code && !safeWindow?.location.hash) {
                return;
            }

            setIsLoading(true);
            setIsLoadingTerms(true);

            const response = await authClient.getOauthCustomer(authType);

            if (response.status === 200) {
                const customer = await authClient.getCustomer();
                setCustomer(new Customer(customer));
                return;
            }

            if (response.status === 201) {
                OAuthTerms.setOauthTerms(response.data);
                await router.push({
                    pathname: RoutesEnum.Terms,
                    query: {
                        signup: TermsAgreeType.Oauth,
                    },
                });
                return;
            }
        } catch (error: any) {
            handleErrors(error.response, () => router.push(RoutesEnum.Login));
        } finally {
            setIsLoading(false);
            setIsLoadingTerms(false);
        }
    };

    const redirectToLogin: RedirectToLogin = (options) => {
        AuthClient.setLoginRedirect(options?.callbackRoute || router.asPath);
        router.replace(RoutesEnum.Login);
    };

    const handleMembershipError = (e?: AxiosError) => {
        const isNoneMemberAllowedPage = [RoutesEnum.ReceiveGift, RoutesEnum.ReceiveGiftFail].some((path) => location.pathname.includes(path));

        if (isNoneMemberAllowedPage) {
            return;
        }

        switch (HARMONY_CONFIG.domain) {
            case MarketDomain.NhPay:
            case MarketDomain.NhPayNormal:
            case MarketDomain.Joymall:
                if (location.pathname === RoutesEnum.ProductDetail && router.query.mode === 'preview') break;
                if (location.pathname !== RoutesEnum.MembershipError) {
                    router.push(RoutesEnum.MembershipError);
                }
                break;
            case MarketDomain.KbPay:
            case MarketDomain.KbOnnuriShop:
                if (location.pathname !== RoutesEnum.MembershipError) {
                    router.replace(RoutesEnum.MembershipError);
                }
                break;
            default:
                break;
        }
    };

    const fetchUserOnLoad = async () => {
        try {
            setIsLoading(true);

            const customer = await authClient.getCustomer();
            setCustomer(new Customer(customer));
        } catch (_) {
            // jwt does not exist or invalid
            if (HARMONY_CONFIG.loginType.includes(LoginType.Membership)) {
                if (typeof externalUserId === 'string') {
                    fetchUserWithExternalToken(externalUserId);
                } else {
                    handleMembershipError();
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Fetches internal user auth using an external token such as
     * a membership id or authorization header
     */
    const fetchUserWithExternalToken: ExternalTokenLogin = async (externalToken: string) => {
        try {
            setIsLoading(true);

            await authClient.getCustomerByExternalToken(externalToken);
            router.replace({ pathname: RoutesEnum.Home, query: {} });

            const customer = await authClient.getCustomer();
            setCustomer(new Customer(customer));
        } catch (e) {
            // failed to load user with membership id
            handleMembershipError(e as AxiosError);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!HARMONY_CONFIG.loginType.includes(LoginType.Membership) && !customer) {
            fetchUserOnLoad();
        }
    }, []);

    useEffect(() => {
        if (HARMONY_CONFIG.loginType.includes(LoginType.Membership) && !customer && router.isReady) {
            if (typeof externalUserId === 'string') {
                fetchUserWithExternalToken(externalUserId);
                return;
            }
            if (ApiService.getAccessToken()) {
                fetchUserOnLoad();
                return;
            }
            handleMembershipError();
        }
    }, [externalUserId, router.isReady]);

    /**
     * When a user is logging in, navigates the user to redirect path if there is any. Otherwise, navigate the user to home.
     */
    useEffect(() => {
        if (isAuth) {
            MobileWebviewService.sendAuth(customer.rawData);

            const redirectPath = AuthClient.getLoginRedirect();
            if (redirectPath) {
                router.replace(redirectPath);
                AuthClient.deleteLoginRedirect();
            } else if ([RoutesEnum.Login, RoutesEnum.OauthProcess].includes(router.pathname as RoutesEnum)) {
                router.replace(RoutesEnum.Home);
            }
        }
    }, [customer]);

    useEffect(() => {
        if (!isValidated && !isPending) {
            handleMembershipError();
        }
    }, [isValidated, isPending]);

    const memoizedValues = useMemo<CustomerProp>(() => {
        return {
            isAuth,
            customer,
            member,
            isLoading,
            isLoadingTerms,
            getCustomer,
            setCustomer,
            register,
            loginCustomer,
            externalTokenLogin: fetchUserWithExternalToken,
            logout,
            deregisterAccount: deregisterAccount,
            oauthLogin,
            redirectToLogin,
            isIdentificationVerified,
            setIsIdentificationVerified,
        };
    }, [customer, isLoading, isIdentificationVerified]);

    return (
        <CustomerContext.Provider value={memoizedValues}>
            <HidingContainer validated={isValidated}>{children}</HidingContainer>
            {isPending && (
                <FixedContainer maxScreenWidth={extensionStatus?.maxScreenWidth ?? 0}>
                    <LegacyFullPageLoader />
                </FixedContainer>
            )}
        </CustomerContext.Provider>
    );
};

const HidingContainer = styled.div<{ validated: boolean }>`
    ${({ validated }) => `
        opacity: ${validated ? 1 : 0};
        pointer-events: ${validated ? 'auto' : 'none'};
    `}
`;

/**
 * Hook to access customer related logic
 */
export function useCustomer(options?: UseCustomerOptions): CustomerProp {
    const context = useContext(CustomerContext);
    const router = useRouter();

    useEffect(() => {
        if (options?.protected && !context.isAuth && !context.isLoading) {
            context.redirectToLogin({ callbackRoute: options.redirectUrl || router.asPath });
        }
    }, [context.isLoading, context.isAuth]);

    useEffect(() => {
        if (options?.requireTerms && !OAuthTerms.getOauthTerms() && !context.isLoadingTerms) {
            router.replace(RoutesEnum.Home);
        }
    }, [context.isLoadingTerms, router.query, router.asPath]);

    useEffect(() => {
        if (options?.externalAuthToken && CONFIG.loginType.includes(LoginType.AuthHeader)) {
            context.externalTokenLogin(options.externalAuthToken);
        }
    }, [options?.externalAuthToken]);

    return context;
}
