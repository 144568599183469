import Sku from '@models/sku';

export enum SortProductByEnum {
    SoldCount = '-sold_count', // 판매순
    ViewCount = '-view_count', // 조회순
    CreateDateDesc = '-approved_dt', // 최신순
    MinSellPrice = 'min_sell_price', // 낮은 가격순
    MinSellPriceDesc = '-min_sell_price', // 높은 가격순
    Ratings = '-ratings', // 평점 높은순
}

export type PriceRange = {
    minSellPrice?: number;
    maxSellPrice?: number;
};

export type ProductFilter = PriceRange & {
    orderBy?: SortProductByEnum | string;
};

export type ReviewFilter = {
    age: string[];
    score: string[];
    gender: string[];
};

export type QuantityType = {
    count: number;
    sku: Sku;
};
